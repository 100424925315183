<template>
  <div class="content-wrapper__layout">
    <side-nav></side-nav>

    <div class="content-wrapper__main-content-wrapper content-wrapper__main-content-wrapper--13">
      <system-nav></system-nav>
      <section class="main-content">
        <section class="section">
          <div class="section__titles">
            <h4 class="section__title">Etiquetas</h4>
            <button class="btn btn--primary btn--outline" @click.prevent="showNewTagDialog()">Nueva etiqueta</button>
          </div>
          <div v-if="disabledExists" class="tabs">
            <div :class="`tab${status ? ' tab--active' : ''}`" @click.prevent="loadData(true)">Habilitadas</div>
            <div :class="`tab${!status ? ' tab--active' : ''}`" @click.prevent="loadData(false)">Deshabilitadas</div>
          </div>
          <div class="list">
            <div class="list__item" v-for="tag in tags" :key="tag.id">
              <div class="item__info">
                <p>{{ tag.name }}</p>
              </div>
              <div class="item__actions">
                <DropdownMenu>
                  <template #button>
                    <button class="btn btn--icon btn--circle">
                      <img src="@/assets/images/icons/ico_dots--bl.svg">
                    </button>
                  </template>
                  <template #options>
                    <a class="dropdown__option" href="#" @click.prevent="showEditTagDialog(tag.id)">
                      Modificar
                    </a>
                      <a :class="['dropdown__option', status ? 'dropdown__option--danger' : '']" href="#" @click.prevent="changeStatus(tag.id)">
                      {{ status ? 'Deshabilitar' : 'Habilitar' }}
                    </a>
                  </template>
                </DropdownMenu>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  </div>

  <!-- Nueva etiqueta -->
  <modal-dialog v-if="newTagDialog">
    <template #title>Nueva etiqueta</template>
    <template #description>Ingresa los datos de la nueva etiqueta</template>
    <template #content>
      <form class="form" @submit.prevent="save()">
        <div class="form__group">
          <label class="form__label" for="name">Nombre</label>
          <input class="form__input" id="name" type="text" v-model.trim="tag.name" :disabled="submitting" required>
        </div>
        <div class="form__group">
          <label class="form__label" for="hex">Color</label>
          <input class="form__input" id="hex" type="color" v-model.trim="tag.hex" :disabled="submitting" required>
        </div>
        <div class="form__actions">
          <button class="btn btn--primary btn--outline" type="button" @click.prevent="newTagDialog = false" :disabled="submitting">Cancelar</button>
          <button class="btn btn--primary" type="submit" :disabled="submitting">Crear</button>
        </div>
      </form>
    </template>
  </modal-dialog>

  <!-- Editar etiqueta -->
  <modal-dialog v-if="editTagDialog">
    <template #title>Editar etiqueta</template>
    <template #description>Modifica los campos de la etiqueta</template>
    <template #content>
      <form class="form" @submit.prevent="update()">
        <div class="form__group">
          <label class="form__label" for="name">Nombre</label>
          <input class="form__input" id="name" type="text" v-model.trim="tag.name" :disabled="submitting" required>
        </div>
        <div class="form__group">
          <label class="form__label" for="hex">Color</label>
          <input class="form__input" id="hex" type="color" v-model.trim="tag.hex" :disabled="submitting" required>
        </div>
        <div class="form__actions">
          <button class="btn btn--primary btn--outline" type="button" @click.prevent="editTagDialog = false" :disabled="submitting">Cancelar</button>
          <button class="btn btn--primary" type="submit" :disabled="submitting">Actualizar</button>
        </div>
      </form>
    </template>
  </modal-dialog>
</template>

<script>
import SideNav from '@/components/SideNav.vue'
import SystemNav from '@/components/SystemNav.vue'
import ModalDialog from '@/components/ModalDialog.vue'
import DropdownMenu from '@/components/DropdownMenu.vue'

export default {
  components: { 
    SideNav,
    SystemNav,
    ModalDialog,
    DropdownMenu
  },
  name: 'Tags',
  data () {
    return {
      tags: [],
      newTagDialog: false,
      editTagDialog: false,
      tag: {
        id: '',
        name: '',
        hex: ''
      },
      submitting: false,
      status: true,
      disabledExists: false
    }
  },
  async created(){
    this.disabledExists = (await this.$axios.get(`tags/total/false`)).data[1] > 0
    await this.loadData(true)
    
  },
  methods: {
    showNewTagDialog () {
      this.tag.name = ''
      this.tag.hex = ''
      this.newTagDialog = true
    },
    async showEditTagDialog (id) {
      this.tag = (await this.$axios.get(`tags/${id}`)).data
      this.editTagDialog = true
    },
    async loadData(status){
      this.status = status
      this.tags = []
      this.tags = (await this.$axios.get(`tags/${this.status}/1`)).data
    },
    async save(){
      this.submitting = true
      const res = await this.$axios.post(`tags/create`, this.tag);
      this.submitting =  false
      if(res.status == 200){
        this.$toast.success(`La etiqueta ha sido creada exitosamente`);
        await this.loadData(this.status)
        this.newTagDialog = false;
      }
      else
        this.$toast.error(`Ocurrió un error al crear la Etiqueta`);
    },
    async update(){
      this.submitting = true
      const res = await this.$axios.post(`tags/update/${this.tag.id}`, this.tag);
      this.submitting =  false
      if(res.status == 200){
        this.$toast.success(`La etiqueta ha sido modificada exitosamente`);
        await this.loadData(this.status)
        this.editTagDialog = false;
      }
      else
        this.$toast.error(`Ocurrió un error al modificar la Etiqueta`);
    },
    async changeStatus(id){
      if(confirm(this.status ? '¿Desea deshabilitar el elemento seleccionado?' : '¿Desea habilitar el elemento seleccionado?')){
        const res = await this.$axios.post(`tags/status/${id}`);
        if(res.status == 200){
          this.disabledExists = (await this.$axios.get(`tags/total/false`)).data[1] > 0
          this.$toast.success(`La etiqueta ha sido modificada exitosamente`);
          if(!this.disabledExists) 
            await this.loadData(true)
          else
            await this.loadData(this.status)
        }
      }
    }
  }
}
</script>